import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Progress } from "antd";

export const Report = (): JSX.Element => {
  const [queryParams, setQueryParams] = useState<Record<string, string>>({});
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    setQueryParams(params);
  }, [location]);

  return (
    <div className="bg-white flex flex-row justify-center w-full">
      <div className="bg-white w-[1280px] h-[2660px] relative">
        <img
          className="absolute w-[287px] h-[286px] top-[1122px] left-[650px] object-cover"
          alt="Rectangle"
          src={`${queryParams.defect_map_back}`}
        />
        <div className="absolute w-[289px] h-[288px] top-[1122px] left-[339px]">
          <img
            className="absolute w-[289px] h-[287px] top-0 left-0 object-cover"
            alt="Rectangle"
            src={`${queryParams.defect_map_front}`}
          />
        </div>
        <img
          className="absolute w-[286px] h-[286px] top-[1440px] left-[339px] object-cover"
          alt="Rectangle"
          src={`${queryParams.defect_map_left}`}
        />
        <img
          className="absolute w-[286px] h-[286px] top-[1440px] left-[650px] object-cover"
          alt="Rectangle"
          src={`${queryParams.defect_map_right}`}
        />
        <div className="absolute w-[859px] h-[853px] top-0 left-0">
          <div className="absolute w-[818px] h-[853px] top-0 left-0">
            <div className="absolute w-[677px] h-[832px] top-0 left-0 bg-white" />
            <div className="absolute top-[138px] left-[486px] [font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-[#333333] text-[40px] text-center tracking-[0] leading-[normal] whitespace-nowrap">
              Generated Report
            </div>
            <div className="absolute top-[246px] left-[340px] [font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-black text-[29px] tracking-[0] leading-[normal] whitespace-nowrap">
              Identification
            </div>
            <img
              className="absolute w-[101px] h-[2px] top-[294px] left-[340px]"
              alt="Line"
              src="/line-1.svg"
            />
            {/* <p className="absolute w-[478px] top-[355px] left-[340px] [font-family:'Lato-Regular',Helvetica] font-normal text-[#909090] text-[16px] tracking-[0] leading-[23px]">
              Royal Oak, a black and stainless quartz wristwatch, Circa 1980s
            </p> */}
            <div className="absolute top-[329px] left-[340px] [font-family:'Lato-Bold',Helvetica] font-bold text-black text-[20px] tracking-[0] leading-[normal] whitespace-nowrap">
              {queryParams.id}
            </div>
            <div className="absolute top-[824px] left-[339px] [font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-[#333333] text-[29px] tracking-[0] leading-[normal] whitespace-nowrap">
              Authentication
            </div>
            <img
              className="absolute w-[160px] h-[16px] top-[45px] left-[40px] object-cover cursor-pointer"
              alt="Chubb logo black RBG"
              src="/logo.png"
              onClick={() => {
                window.location.href = "/";
              }}
            />
            <img
              className="absolute w-[165px] h-[166px] top-[413px] left-[617px] object-cover"
              alt="Rectangle"
              src={`${queryParams.image_url_back}`}
            />
            <img
              className="absolute w-[165px] h-[166px] top-[413px] left-[440px] object-cover"
              alt="Rectangle"
              src={`${queryParams.image_url_front}`}
            />
            <img
              className="absolute w-[165px] h-[166px] top-[590px] left-[440px] object-cover"
              alt="Rectangle"
              src={`${queryParams.image_url_left}`}
            />
            <img
              className="absolute w-[165px] h-[166px] top-[590px] left-[617px] object-cover"
              alt="Rectangle"
              src={`${queryParams.image_url_right}`}
            />
          </div>
        </div>
        <div className="absolute w-[191px] h-[179px] top-[1772px] left-[339px] bg-[#eeeeee] rounded-[6px] border border-solid border-[#d9d9d9]">
          <div className="absolute top-[140px] left-[56px] [font-family:'Lato-SemiBold',Helvetica] font-semibold text-black text-[14px] text-center tracking-[0] leading-[normal]">
            Wear &amp; Tear
          </div>
          <div className="top-[10px] left-[35px] [font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-center tracking-[0] leading-[normal] absolute text-black text-[24px] whitespace-nowrap">
            <Progress
              type="circle"
              percent={90}
              format={() => 9}
              strokeColor="#12B347"
            />
          </div>
        </div>
        <div className="absolute w-[190px] h-[179px] top-[1772px] left-[543px] bg-[#eeeeee] rounded-[6px] border border-solid border-[#d9d9d9]">
          <div className="absolute top-[140px] left-[76px] [font-family:'Lato-SemiBold',Helvetica] font-semibold text-black text-[14px] text-center tracking-[0] leading-[normal]">
            Color
          </div>
          <div className="top-[10px] left-[35px] [font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-center tracking-[0] leading-[normal] absolute text-black text-[24px] whitespace-nowrap">
            <Progress
              type="circle"
              percent={80}
              format={() => 8}
              strokeColor="#12B347"
            />
          </div>
        </div>
        <div className="absolute w-[191px] h-[179px] top-[1772px] left-[746px] bg-[#eeeeee] rounded-[6px] border border-solid border-[#d9d9d9]">
          <div className="absolute top-[140px] left-[74px] [font-family:'Lato-SemiBold',Helvetica] font-semibold text-black text-[14px] text-center tracking-[0] leading-[normal]">
            Fading
          </div>
          <div className="top-[10px] left-[35px] [font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-center tracking-[0] leading-[normal] absolute text-black text-[24px] whitespace-nowrap">
            <Progress
              type="circle"
              percent={80}
              format={() => 8}
              strokeColor="#12B347"
            />
          </div>
        </div>
        <div className="absolute w-[191px] h-[179px] top-[1965px] left-[339px] bg-[#eeeeee] rounded-[6px] border border-solid border-[#d9d9d9]">
          <div className="absolute top-[140px] left-[63px] [font-family:'Lato-SemiBold',Helvetica] font-semibold text-black text-[14px] text-center tracking-[0] leading-[normal]">
            Scratches
          </div>
          <div className="top-[10px] left-[35px] [font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-center tracking-[0] leading-[normal] absolute text-black text-[24px] whitespace-nowrap">
            <Progress
              type="circle"
              percent={70}
              format={() => 7}
              strokeColor="#D6B560"
            />
          </div>
        </div>
        <div className="absolute w-[190px] h-[179px] top-[1965px] left-[543px] bg-[#eeeeee] rounded-[6px] border border-solid border-[#d9d9d9]">
          <div className="absolute top-[140px] left-[59px] [font-family:'Lato-SemiBold',Helvetica] font-semibold text-black text-[14px] text-center tracking-[0] leading-[normal]">
            Alterations
          </div>
          <div className="top-[10px] left-[35px] [font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-center tracking-[0] leading-[normal] absolute text-black text-[24px] whitespace-nowrap">
            <Progress
              type="circle"
              percent={100}
              format={() => 10}
              strokeColor="#12B347"
            />
          </div>
        </div>
        <div className="absolute w-[191px] h-[179px] top-[1965px] left-[746px] bg-[#eeeeee] rounded-[6px] border border-solid border-[#d9d9d9]">
          <div className="absolute top-[140px] left-[69px] [font-family:'Lato-SemiBold',Helvetica] font-semibold text-black text-[14px] text-center tracking-[0] leading-[normal]">
            Damage
          </div>
          <div className="top-[10px] left-[35px] [font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-center tracking-[0] leading-[normal] absolute text-black text-[24px] whitespace-nowrap">
            <Progress
              type="circle"
              percent={80}
              format={() => 8}
              strokeColor="#12B347"
            />
          </div>
        </div>
        <div className="absolute w-[194px] h-[108px] top-[2158px] left-[339px] bg-[#eeeeee] rounded-[4px] border border-solid border-[#d9d9d9]">
          <div className="top-[25px] left-[18px] [font-family:'Lato-Bold',Helvetica] font-bold tracking-[-0.41px] leading-[22px] absolute text-black text-[24px] whitespace-nowrap">
            2019
          </div>
          <div className="absolute top-[60px] left-[18px] [font-family:'Lato-Regular',Helvetica] font-normal text-[#909090] text-[18px] tracking-[-0.41px] leading-[normal]">
            Year
          </div>
        </div>
        <div className="absolute w-[196px] h-[108px] top-[2158px] left-[540px] bg-[#eeeeee] rounded-[4px] border border-solid border-[#d9d9d9]">
          <div className="left-[12px] absolute top-[25px] [font-family:'Lato-Bold',Helvetica] font-bold text-black text-[24px] tracking-[-0.41px] leading-[22px] whitespace-nowrap">
            Included
          </div>
          <div className="absolute top-[60px] left-[12px] [font-family:'Lato-Regular',Helvetica] font-normal text-[#909090] text-[18px] tracking-[-0.41px] leading-[normal]">
            Box
          </div>
        </div>
        <div className="absolute w-[194px] h-[108px] top-[2158px] left-[743px] bg-[#eeeeee] rounded-[4px] border border-solid border-[#d9d9d9]">
          <div className="left-[13px] absolute top-[25px] [font-family:'Lato-Bold',Helvetica] font-bold text-black text-[24px] tracking-[-0.41px] leading-[22px] whitespace-nowrap">
            Included
          </div>
          <div className="absolute top-[60px] left-[13px] [font-family:'Lato-Regular',Helvetica] font-normal text-[#909090] text-[18px] tracking-[-0.41px] leading-[normal]">
            Papers
          </div>
        </div>
        <p className="absolute w-[596px] top-[951px] left-[340px] [font-family:'Lato-Regular',Helvetica] font-normal text-[#909090] text-[14px] tracking-[0] leading-[18px]">
          {queryParams.grading}
        </p>
        <div className="absolute top-[907px] left-[374px] [font-family:'Lato-Bold',Helvetica] font-bold text-black text-[18px] tracking-[0] leading-[normal]">
          Authentic
        </div>
        <div className="absolute w-[24px] h-[24px] top-[908px] left-[340px] bg-[#d5b460] rounded-[12px]">
          <img
            className="absolute w-[13px] h-[13px] top-[5px] left-[5px]"
            alt="Check"
            src="/check.svg"
          />
        </div>
        <div className="absolute top-[907px] left-[780px] [font-family:'Lato-Regular',Helvetica] font-normal text-black text-[18px] tracking-[0] leading-[normal]">
          {queryParams.price} USD
        </div>
        <div className="absolute top-[911px] left-[883px] [font-family:'Lato-Regular',Helvetica] font-normal text-[#909090] text-[12px] tracking-[0] leading-[normal] whitespace-nowrap">
          est value
        </div>
        <img
          className="top-[872px] left-[341px] absolute w-[101px] h-[2px]"
          alt="Line"
          src="/line-1.svg"
        />
        <div className="absolute top-[1040px] left-[339px] [font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-black text-[29px] tracking-[0] leading-[normal] whitespace-nowrap">
          Defect Recognition
        </div>
        <img
          className="top-[1088px] left-[339px] absolute w-[101px] h-[2px]"
          alt="Line"
          src="/line-1.svg"
        />
        <div
          className="absolute w-[369px] h-[49px] top-[2326px] left-[455px] bg-black cursor-pointer"
          onClick={() => {
            window.location.href = "/upload";
          }}
        >
          <div className="absolute top-[15px] left-[94px] [font-family:'Lato-Bold',Helvetica] font-bold text-[#eeeeee] text-[14px] text-center tracking-[0] leading-[normal]">
            {" "}
            Authenticate Another Watch
          </div>
        </div>
        <p className="absolute top-[2520px] left-[40px] [font-family:'Lato-Regular',Helvetica] font-normal text-black text-[14px] tracking-[0] leading-[normal]">
          <span className="[font-family:'Lato-Regular',Helvetica] font-normal text-black text-[14px] tracking-[0]">
            Powered by{" "}
          </span>
          <span className="[font-family:'Lato-Bold',Helvetica] font-bold">
            Wrist Shot
          </span>
        </p>
      </div>
    </div>
  );
};
