import React from "react";

export const Main = (): JSX.Element => {
  return (
    <div className="bg-white flex flex-row justify-center w-full">
      <div className="bg-white w-[1280px] h-[832px] relative">
        <div className="absolute w-[640px] h-[832px] top-0 left-0 bg-white">
          <div className="absolute top-[309px] left-[165px] [font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-[#333333] text-[40px] tracking-[0] leading-[normal] whitespace-nowrap">
            Authentication AI
          </div>
          <img
            className="absolute w-[101px] h-[2px] top-[470px] left-[40px]"
            alt="Line"
            src="line-1.svg"
          />
          <p className="absolute w-[566px] top-[373px] left-[40px] [font-family:'Lato-Regular',Helvetica] font-normal text-[#0f0f0f] text-[18px] tracking-[0] leading-[26px]">
            Submit photos of your watch, and we will generate a comprehensive
            report including identification, authentication, assessment, and
            appraisal.
          </p>
          <div
            className="absolute w-[371px] h-[49px] top-[518px] left-[135px] hover:cursor-pointer"
            onClick={() => {
              window.location.href = "/upload";
            }}
          >
            <div className="relative w-[369px] h-[49px] bg-black">
              <div className="absolute top-[17px] left-[148px] [font-family:'Lato-SemiBold',Helvetica] font-semibold text-[#eeeeee] text-[14px] text-center tracking-[0] leading-[normal]">
                Get Started
              </div>
            </div>
          </div>
          <div className="absolute w-[297px] h-[61px] top-[666px] left-[171px]">
            <div className="absolute w-[59px] h-[59px] top-[2px] left-0 bg-[#6e27c51c] rounded-[29.5px] cursor-pointer">
              <img
                className="absolute w-[48px] h-[48px] top-[3px] left-[9px]"
                alt="Sneakers"
                src="sneakers-1.svg"
              />
            </div>
            <div className="absolute w-[59px] h-[59px] top-0 left-[119px] bg-[#6e27c51c] rounded-[29.5px] cursor-pointer">
              <img
                className="absolute w-[40px] h-[40px] top-[9px] left-[10px]"
                alt="Necklace"
                src="necklace-1.svg"
              />
            </div>
            <div className="absolute w-[59px] h-[59px] top-[2px] left-[238px] bg-[#6e27c51c] rounded-[29.5px] cursor-pointer">
              <img
                className="absolute w-[40px] h-[39px] top-[8px] left-[10px]"
                alt="Handbag"
                src="handbag-1.svg"
              />
            </div>
          </div>
          <p className="absolute top-[790px] left-[40px] [font-family:'Lato-Regular',Helvetica] font-normal text-[#0f0f0f] text-[14px] tracking-[0] leading-[normal]">
            <span className="[font-family:'Lato-Regular',Helvetica] font-normal text-[#0f0f0f] text-[14px] tracking-[0]">
              Powered by{" "}
            </span>
            <span className="[font-family:'Lato-SemiBold',Helvetica] font-semibold">
              Wrist Shot
            </span>
          </p>
          <p className="absolute top-[615px] left-[171px] [font-family:'Lato-Medium',Helvetica] font-medium text-[#0f0f0f] text-[16px] text-center tracking-[0] leading-[normal] whitespace-nowrap">
            Choose another category to authenticate:
          </p>
          <img
            className="absolute w-[160px] h-[16px] top-[45px] left-[40px] object-cover hover:cursor-pointer"
            alt="Chubb logo black RBG"
            src="/logo.png"
            onClick={() => {
              window.location.href = "/";
            }}
          />
        </div>
        <img
          className="absolute w-[603px] h-[832px] top-0 left-[677px] object-cover"
          alt="Rectangle"
          src="rectangle-60@2x.png"
        />
      </div>
    </div>
  );
};
