import React from "react";
import { Link } from "react-router-dom";
import UploadComp from "../components/upload.com";
import { useMemo, useState } from "react";
import { UploadFile, notification } from "antd";
import axios from "axios";
interface AxiosBody {
  image_url_front?: string;
  image_url_back?: string;
  image_url_left?: string;
  image_url_right?: string;
  image_url_top?: string;
  image_url_bottom?: string;
}
const Context = React.createContext({ name: "Default" });

export const Upload = (): JSX.Element => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);

  const openNotification = (text: string, type: string) => {
    if (type === "info")
      api.info({
        message: "Info",
        description: text,
        placement: "topRight",
      });
    else
      api.error({
        message: "Error",
        description: text,
        placement: "topRight",
      });
  };

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  const handleGetReport = async () => {
    if (loading) return;
    try {
      if (fileList.length < 6) {
        if (
          fileList.find((file) => file.name.endsWith("_front"))?.url ===
          undefined
        ) {
          openNotification("Upload the front image to continue", "error");
          return;
        } else if (
          fileList.find((file) => file.name.endsWith("_back"))?.url ===
          undefined
        ) {
          openNotification("Upload the back image to continue", "error");
          return;
        } else if (
          fileList.find((file) => file.name.endsWith("_left"))?.url ===
          undefined
        ) {
          openNotification("Upload the left image to continue", "error");
          return;
        } else if (
          fileList.find((file) => file.name.endsWith("_right"))?.url ===
          undefined
        ) {
          openNotification("Upload the right image to continue", "error");
          return;
        }
      }

      setLoading(true);

      openNotification("Processing...", "info");

      const axiosBody: AxiosBody = {
        image_url_front: fileList.find((file) => file.name.endsWith("_front"))
          ?.url,
        image_url_back: fileList.find((file) => file.name.endsWith("_back"))
          ?.url,
        image_url_left: fileList.find((file) => file.name.endsWith("_left"))
          ?.url,
        image_url_right: fileList.find((file) => file.name.endsWith("_right"))
          ?.url,
      };

      if (
        fileList.find((file) => file.name.endsWith("_tag"))?.url !== undefined
      ) {
        axiosBody["image_url_top"] = fileList.find((file) =>
          file.name.endsWith("_tag")
        )?.url;
      }

      if (
        fileList.find((file) => file.name.endsWith("_bottom"))?.url !==
        undefined
      ) {
        axiosBody["image_url_bottom"] = fileList.find((file) =>
          file.name.endsWith("_bottom")
        )?.url;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/grading`,
        axiosBody
      );
      setLoading(false);
      window.location.href = `/report/?id=${encodeURIComponent(
        response.data.data.ID
      )}&grading=${encodeURIComponent(
        response.data.data.Grading
      )}&price=${encodeURIComponent(
        response.data.data.Price
      )}&defect_map_front=${encodeURIComponent(
        response.data.data.Defect_map_front
      )}&defect_map_back=${encodeURIComponent(
        response.data.data.Defect_map_back
      )}&defect_map_left=${encodeURIComponent(
        response.data.data.Defect_map_left
      )}&defect_map_right=${encodeURIComponent(
        response.data.data.Defect_map_right
      )}&image_url_front=${encodeURIComponent(
        fileList.find((file) => file.name.endsWith("_front"))?.url || ""
      )}&image_url_back=${encodeURIComponent(
        fileList.find((file) => file.name.endsWith("_back"))?.url || ""
      )}&image_url_left=${encodeURIComponent(
        fileList.find((file) => file.name.endsWith("_left"))?.url || ""
      )}&image_url_right=${encodeURIComponent(
        fileList.find((file) => file.name.endsWith("_right"))?.url || ""
      )}`;
    } catch (error) {
      setLoading(false);
      openNotification("Something went wrong", "error");
      throw error;
    }
  };

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <div className="bg-white flex flex-row justify-center w-full">
        <div className="bg-white w-[1280px] h-[832px] relative">
          <div className="absolute w-[640px] h-[832px] top-0 left-0 bg-white">
            <p className="absolute w-[566px] top-[373px] left-[40px] [font-family:'Inter-Regular',Helvetica] font-normal text-[#333333] text-[18px] tracking-[0] leading-[normal]">
              Please upload at least three well-lit photographs of your watch
              against a dark background, avoiding the use of flash. This will
              enhance the precision of the report.
            </p>
            <div
              className="absolute w-[83px] h-[47px] top-[745px] left-[281px] hover:cursor-pointer"
              onClick={() => {
                window.location.href = "/";
              }}
            >
              <div className="absolute top-0 left-[27px] [font-family:'Inter-Regular',Helvetica] font-normal text-[#909090] text-[14px] text-center tracking-[0] leading-[normal]">
                Exit
              </div>
              <div className="absolute top-[30px] left-0 [font-family:'Inter-Bold',Helvetica] font-bold text-[#6e27c5] text-[14px] text-center tracking-[0] leading-[normal]">
                Need Help?
              </div>
            </div>
            <div className="absolute w-[296px] h-[157px] top-[524px] left-[172px]">
              <div className="relative w-[322px] h-[183px] top-[-9px] left-[-13px]">
                <img
                  className="w-[146px] h-[183px] top-0 left-0 absolute object-cover"
                  alt="Watch png transform"
                  src="watch-png-transform-1.png"
                />
                <img
                  className="w-[183px] h-[152px] top-[15px] left-[139px] absolute object-cover"
                  alt="Watch png transform"
                  src="watch-png-transform-2.png"
                />
              </div>
            </div>
            <p className="absolute top-[13px] left-[40px] [font-family:'Inter-Bold',Helvetica] font-bold text-transparent text-[32px] tracking-[0] leading-[normal] hover:cursor-pointer">
              <img
                className="fixed w-[160px] h-[16px] object-cover"
                alt="Chubb logo black RBG"
                src="/logo.png"
                onClick={() => {
                  window.location.href = "/";
                }}
              />
            </p>
            <div className="absolute top-[309px] left-[224px] [font-family:'Inter-Bold',Helvetica] font-bold text-[#333333] text-[40px] text-center tracking-[0] leading-[normal] whitespace-nowrap">
              Add Photos
            </div>
            <img
              className="absolute w-[101px] h-[2px] top-[465px] left-[40px]"
              alt="Line"
              src="line-1.svg"
            />
          </div>

          <div className="relative w-[603px] h-[832px] top-0 left-[677px] bg-white border-l [border-left-style:solid] border-[#b4b4b4]">
            <div className="absolute top-[71px] left-[40px] [font-family:'Publico_Headline_Web-Roman',Helvetica] font-normal text-[#333333] text-[29px] tracking-[0] leading-[normal] whitespace-nowrap">
              Upload Photos
            </div>
            <div className="relative w-[519px] h-[135px] top-[144px] left-[41px] bg-[#6e27c50a] rounded-[4px] border border-dashed border-[#6e27c5]">
              <p className="relative top-[51px] left-[106px] [font-family:'Inter-Bold',Helvetica] font-normal text-transparent text-[16px] tracking-[0] leading-[normal] whitespace-nowrap">
                <span className="font-bold text-[#ffb617]">Upload Photos </span>
                <span className="[font-family:'Inter-Regular',Helvetica] text-[#333333]">
                  from your computer. We need 4 photos
                  <br />
                  of your watch.
                </span>
              </p>

              <div className="absolute w-[48px] h-[48px] top-[43px] left-[38px] bg-[#afafaf54] rounded-[24px]">
                <img
                  className="absolute w-[38px] h-[38px] top-[7px] left-[4px]"
                  alt="Gallery"
                  src="gallery-2-1.svg"
                />
              </div>
            </div>
            <div className="relative top-[171px] left-[40px]">
              <UploadComp fileList={fileList} setFileList={setFileList} />
            </div>
            <Link
              className="absolute w-[369px] h-[49px] top-[837px] left-[116px]"
              to="/upload"
            >
              <div className="w-[371px] h-[49px]" onClick={handleGetReport}>
                <div className="relative w-[369px] h-[49px] bg-[#0f0f0f]">
                  <div className="absolute top-[16px] left-[147px] [font-family:'Inter-SemiBold',Helvetica] font-semibold text-[#eeeeee] text-[14px] text-center tracking-[0] leading-[normal]">
                    Get Report
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Context.Provider>
  );
};
