import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Radio, Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile, UploadFileStatus } from "antd/es/upload/interface";
import axios from "axios";

type UploadCompProps = {
  fileList: UploadFile[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
};

const perspectives = ["front", "back", "left", "right"];

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const getPresignedUrl = async () => {
  try {
    // Replace with your API endpoint to get the pre-signed URL
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/aws-s3/get-pre-signed-post-url-demo-assajk6223`,
      {
        type: "Watch",
      }
    );
    return response.data.data;
  } catch (error) {
    console.error("Error getting pre-signed URL from backend", error);
    throw error;
  }
};

const UploadComp: React.FC<UploadCompProps> = ({ fileList, setFileList }) => {
  const [selectedPerspective, setSelectedPerspective] = useState(
    perspectives[0]
  );
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  // const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || file.preview || "");
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleUpload = async (file: RcFile) => {
    try {
      const presignedData = await getPresignedUrl();

      const formData = new FormData();
      Object.entries(presignedData.fields).forEach(([key, value]) => {
        formData.append(key, value as string);
      });
      formData.append("file", file); // Append the file last

      await axios.post(presignedData.url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return {
        uid: file.uid,
        name: `${file.name}_${selectedPerspective}`,
        status: "done" as UploadFileStatus,
        url: presignedData.url + "/" + presignedData.fields.key, // Construct the file URL
      };
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleChange: UploadProps["onChange"] = async ({ file }) => {
    if (file.status === undefined) {
      const uploadedFile = await handleUpload(file as RcFile);
      if (uploadedFile) {
        const updatedFileList = fileList.filter(
          (f) => !f.name.startsWith(selectedPerspective + "_")
        );
        updatedFileList.push({
          ...uploadedFile,
          name: selectedPerspective + "_" + uploadedFile.name,
        });
        setFileList(updatedFileList);
      }
    } else if (file.status === "removed") {
      const updatedFileList = fileList.filter(
        (f) => !f.name.startsWith(selectedPerspective + "_")
      );
      setFileList(updatedFileList);
    }
  };

  const filteredFileList = fileList.filter((file) =>
    file.name.startsWith(selectedPerspective + "_")
  );

  const renderImageGrid = () => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        {fileList.map((file) => (
          <div key={file.uid} style={{ width: "150px", height: "150px" }}>
            <img
              src={file.url}
              alt={file.name}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <Radio.Group
        options={perspectives.map((p) => ({ label: p, value: p }))}
        value={selectedPerspective}
        onChange={(e) => setSelectedPerspective(e.target.value)}
      />
      <Upload
        listType="picture-card"
        fileList={filteredFileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={() => false} // Prevent default upload
      >
        {filteredFileList.length < 1 && <PlusOutlined />}
      </Upload>
      {renderImageGrid()}
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default UploadComp;
